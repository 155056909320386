import React, { useState, useEffect, MouseEvent } from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage, ImageDataLike } from 'gatsby-plugin-image';
import { BLOCKS } from '@contentful/rich-text-types';
import InsightsWhitePaperStyled from '@/components/styled/InsightsWhitePaperStyled';
import { IContentfulWhitepaper } from '@/types';
import ContentfulRawToComponent from '@/components/utils/ContentfulRawToComponent';
import Helpers from '@/utils/helpers';
import { usePageMeta } from '@/components/app/hooks';
import Reveal from '@/components/reveal/Reveal';
// import HubspotFormContainer from '@/components/sections/hubspotformcontainer/HubspotFormContainer';

// eslint-disable-next-line no-shadow
enum Form {
  SUCCESS,
  FAILED,
  NORMAL,
}

const ContentfulWhitepaper: React.FC<IContentfulWhitepaper> = ({ data }) => {
  const windowWidth = Helpers.getWindowInnerWidth();
  const laptop = windowWidth <= Helpers.window.breakpoint.desktop - 1;
  const isTablet = windowWidth <= Helpers.window.breakpoint.tablet;
  const [formState] = useState<Form>(Form.NORMAL);

  const handleLinkClick = (event: MouseEvent<HTMLAnchorElement>) => {
    const link = event.currentTarget.href;
    const target = event.currentTarget.target || `_self`;

    if (link.includes(`#`)) {
      const url = link.split(`#`);
      const targetElement = document.getElementById(url[1]);

      if (target) {
        event.preventDefault();
        event.stopPropagation();
        targetElement.scrollIntoView({
          behavior: `smooth`,
        });
      }
    }
  };

  useEffect(() => {
    if (formState === Form.SUCCESS) {
      setTimeout(() => {
        window.open(data.contentfulWhitepaper?.file?.file?.url, `_blank`);
      }, 1000);
    }
  }, [formState]);

  const override = {
    renderNode: {
      [BLOCKS.LIST_ITEM]: ({ content }, _children) => (
        <li>
          <h1>{content[0].content[0].value}</h1>
        </li>
      ),
    },
  };

  const showFeaturedImage = (): ImageDataLike =>
    isTablet
      ? data.contentfulWhitepaper?.mobileImage?.image?.gatsbyImageData
      : data.contentfulWhitepaper?.image?.image?.gatsbyImageData;

  const renderForm = () => (
    <>
      <div className="details">
        <div className="whitepaper-image container">
          <Reveal className="banner">
            <div className="image-container whitepaper-image-container">
              <GatsbyImage
                image={getImage(showFeaturedImage())}
                alt={data.contentfulWhitepaper?.image?.image?.title || ``}
              />
            </div>
          </Reveal>
        </div>

        <Reveal className="post-container">
          <div className="page-label-container">
            <h5 className="label">IONA WHITEPAPER</h5>
          </div>
          <div className="title-container">
            <h1>{data.contentfulWhitepaper.title}</h1>
            {data.contentfulWhitepaper?.pipedriveId && (
              <a
                href="#download-form"
                className="btn"
                onClick={handleLinkClick}
              >
                Download Now
              </a>
            )}
          </div>
          <div className="featured-remarks">
            <ContentfulRawToComponent
              raw={data.contentfulWhitepaper?.featuredRemarks?.raw}
              options={override}
            />
          </div>
        </Reveal>

        <Reveal className="post-container">
          <div className="remarks">
            {laptop && (
              <ContentfulRawToComponent
                raw={data.contentfulWhitepaper?.featuredRemarks?.raw}
                options={override}
              />
            )}
            <ContentfulRawToComponent
              raw={data.contentfulWhitepaper?.remarks?.raw}
              options={override}
            />
          </div>
        </Reveal>
      </div>
      {/*
      <Reveal className="content">
        <div className="post-container">
          <HubspotFormContainer />
        </div>
      </Reveal>
      */}
    </>
  );

  const renderSuccessfullySubscribed = () => (
    <>
      <Reveal className="main-details">
        <div className="title-container">
          <div className="subscribed-message">
            <h2>Thank you!</h2>
          </div>
        </div>
      </Reveal>

      <Reveal className="content">
        <div className="subscribed-guide">
          <h3>Your download should have started.</h3>
        </div>
      </Reveal>
    </>
  );

  return (
    <InsightsWhitePaperStyled>
      {usePageMeta(data.contentfulWhitepaper?.meta)}
      <div className="content container single-article-page whitepaper-page">
        <Reveal className={`${laptop ? `fadeInUp` : ``}`}>
          <div className="page-title link-title">
            <Link className="link back" to="/insights">
              All insights
            </Link>
          </div>
        </Reveal>
        <Reveal>
          <div className={`${formState === Form.SUCCESS ? `subscribed` : ``}`}>
            {formState === Form.SUCCESS
              ? renderSuccessfullySubscribed()
              : renderForm()}
          </div>
        </Reveal>
      </div>
    </InsightsWhitePaperStyled>
  );
};

export const query = graphql`
  query Whitepaper($id: String) {
    contentfulWhitepaper(id: { eq: $id }) {
      title
      slug
      featuredRemarks {
        raw
      }
      remarks {
        raw
      }
      image {
        image {
          gatsbyImageData
        }
      }
      mobileImage {
        image {
          gatsbyImageData
        }
      }
      pipedriveId
      file {
        file {
          url
          contentType
          fileName
          details {
            size
          }
        }
      }
      meta {
        title
        description
        type
        image {
          file {
            url
          }
        }
      }
    }
  }
`;

export default ContentfulWhitepaper;
